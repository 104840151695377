import React, { useState, useEffect } from "react";
import editprofile from "./editprofile.module.css";
import cancelicon from "../../assets/svg/cancel-icon.svg";
import { update } from "lodash";
import axios from "axios";
import { message } from "antd";
import DatePicker from "react-datepicker";
import moment from 'moment'
import  { isAuthenticated } from '../../utils/authdata'
import "react-datepicker/dist/react-datepicker.css";

// export const EditPassword = ({ setStep, closeSettings }) => {
//   return (
//     <div className={editprofile.editwrap}>
//       <div className={editprofile.cancel}>
//         <img src={cancelicon} alt="cancel" onClick={closeSettings} />
//       </div>
//       <div
//         style={{
//           fontSize: "20px",
//           color: "#123873",
//           justifyContent: "space-between",
//           alignItems: "center",
//           fontWeight: "bold",
//           display: "flex",
//         }}
//       >
//         <div>Update Password</div>
//         <div
//           style={{ color: "#000", fontSize: "14px", cursor: "pointer" }}
//           onClick={() => setStep(0)}
//         >
//           Update profile
//         </div>
//       </div>
//       <div style={{ marginTop: "50px" }}>
//         <div>
//           <div className={editprofile.label}>
//             <label>Old Password</label>
//           </div>
//           <input type="password" placeholder="Old Password" />
//         </div>

//         <div>
//           <div className={editprofile.label}>
//             <label>New Password</label>
//           </div>
//           <input type="password" placeholder="New Password" />
//         </div>

//         <div>
//           <div className={editprofile.label}>
//             <label>Confirm Password</label>
//           </div>
//           <input type="password" placeholder="Confirm Password" />
//         </div>

//         <div className={editprofile.content}>
//           Password must contain a minimum of 6 character with a combination of
//           letters and digits. Password should contain uppercase letters,
//           lowercase letters and special characters
//         </div>

//         <div>
//           <button className={editprofile.btn}>Save changes</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const EditProfile = ({ closeSettings }) => {
//   const [step, setStep] = useState(0);
//   const changeToEditProfile = () => {
//     setStep(0);
//   };

//   const [updateProfile, setUpdateProfile] = useState({
//     name: "",
//     gender: "",
//     dob: "",
//     phone: "",
//   });

//   const handleChange = ({ target }) => {
//     const { name, value } = target;

//     setUpdateProfile((update) => ({ ...update, [name]: value }));
//   };

//   const handleClick = ({ target }) => {
//     const { innerHTML, name } = target;
//     setUpdateProfile((update) => ({ ...update, [name]: innerHTML }));

//     if (updateProfile.gender === innerHTML) {
//       target.style.backgroundColor = "#2B9FDD";
//       target.style.color = "#fff";
//     } else {
//       target.style.backgroundColor = "transparent";
//       target.style.color = "#000";
//     }
//   };

//   const getToken = () => {
//     const token = JSON.parse(localStorage?.getItem("user"))?.token;
//     return {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };
//   };

//   const submitChange = (e) => {
//     e.preventDefault();
//     const config = getToken();
//     console.log(updateProfile);
//     axios
//       .post(
//         `http://138.68.158.131:8081/update-user-profile`,
//         updateProfile,
//         config
//       )
//       .then((res) => {
//         console.log(res.data);
//         closeSettings();
//         if (res.data.desc == "OK") {
//           message.success({
//             content: `${res?.data?.desc}, ${res?.data?.obj}`,
//             duration: 3,
//           });
//         }
//       })
//       .catch((err) => {
//         message.error({
//           content: err?.response?.data?.error || "connection error",
//           duration: 3,
//         });
//       });
    
//   };

//   return (
//     <>
//       {step === 0 && (
//         <div className={editprofile.editwrap}>
//           <div className={editprofile.cancel}>
//             <img src={cancelicon} alt="cancel" onClick={closeSettings} />
//           </div>

//           <div
//             style={{
//               fontSize: "20px",
//               color: "#123873",
//               justifyContent: "space-between",
//               alignItems: "center",
//               fontWeight: "bold",
//               display: "flex",
//             }}
//           >
//             <div>Update Profile</div>
//             <div
//               style={{ color: "#000", fontSize: "14px", cursor: "pointer" }}
//               onClick={() => setStep(1)}
//             >
//               Request Otp
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               alignItems: "center",
//               marginTop: "30px",
//             }}
//           >
         
//           </div>

//           <div>
//             <div style={{ marginTop: "30px" }}>
//               <div className={editprofile.label}>
//                 <label>Full name</label>
//               </div>
//               <input
//                 className={`form-contro ${editprofile.form_group}`}
//                 type="text"
//                 name="name"
//                 placeholder="Adewale Wasiu Babatunde"
//                 onChange={handleChange}
//               />
//             </div>
//             <div style={{ marginBottom: "20px" }}>
//               <div className={editprofile.label}>
//                 <label>Gender</label>
//               </div>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <button
//                   className={`form-contro ${editprofile.form_group}`}
//                   style={{
//                     padding: "15px 15px",
//                     width: "40%",
//                     background:
//                       updateProfile.gender === "Female"
//                         ? "#2B9FDD"
//                         : "transparent",
//                     borderRadius: "50px",
//                     cursor: "pointer",
//                     outline: "none",
//                     border: "1px solid #000",
//                     color: updateProfile.gender === "Female" && "#fff",
//                   }}
//                   name="gender"
//                   onClick={handleClick}
//                 >
//                   Female
//                 </button>
//                 <button
//                   className={`form-contro ${editprofile.form_group}`}
//                   style={{
//                     padding: "15px 15px",
//                     width: "40%",
//                     background:
//                       updateProfile.gender === "Male"
//                         ? "#2B9FDD"
//                         : "transparent",
//                     borderRadius: "50px",
//                     cursor: "pointer",
//                     outline: "none",
//                     border: "1px solid #000",
//                     color: updateProfile.gender === "Male" && "#fff",
//                   }}
//                   name="gender"
//                   onClick={handleClick}
//                 >
//                   Male
//                 </button>
//               </div>
//             </div>
//             <div>
//               <div className={editprofile.label}>
//                 <label>Date of Birth</label>
//               </div>
//               <input
//                 className={`form-contro ${editprofile.form_group}`}
//                 type="date"
//                 name="dob"
//                 placeholder="December 21, 1990"
//                 onChange={handleChange}
//               />
//             </div>
//             <div>
//               <div className={editprofile.label}>
//                 <label>Phone Number</label>
//               </div>
//               <input
//                 className={`form-contro ${editprofile.form_group}`}
//                 type="text"
//                 name="phone"
//                 placeholder="08012345678"
//                 onChange={handleChange}
//               />
//             </div>
//             <div>
//               <button onClick={submitChange} className={editprofile.btn}>
//                 Save Changes
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {step === 1 && (
//         <EditPassword setStep={setStep} closeSettings={closeSettings} />
//       )}
//     </>
//   );
// };

const getToken = () => {
  const token = JSON.parse(localStorage?.getItem("user"))?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
const PasswordChange = ({ email, setProfileSettings }) => {
  console.log(email, 'kksksk')
  
  const [step1, setStep1] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [updatePassword, setUpdatePassword] = useState({
    password: "",
    email: "",
    otp: "",
  });
  const handleChange = (event) => {
    const { value, name } = event.target
    setUpdatePassword({...updatePassword, [name]: value})

  };
  const HandlePaswwordreset = ()=>{
    setLoading(true)
      const config = getToken()
      axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/reset-password`, {...updatePassword}, config)
    .then((res)=>{
      console.log(res)
      setLoading(false)
      if(res.data.desc == "OK" && res.data.status == 0){
        setProfileSettings(false)
        console.log('jjsjs')
        message.success({
          content: `${res?.data?.desc}, ${res?.data?.obj}`,
          duration: 3,
        });

      }
      
    })
    .catch((err )=>{
        console.log(err.response)
        setLoading(false)
    })
  }

  const requestOtp = () => {
    setLoading1(true)
    const config = getToken()
    const email = {
      email:updatePassword.email
    }
    axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/reset`, {...email}, config)
    .then((res)=>{
      console.log(res)
      setLoading1(false)
      if(res.data.desc == "OK" && res.data.status == 0){
        setStep1(1)
        console.log('jjsjs')

      }
      
    })
    .catch((err )=>{
        console.log(err.response)
        setLoading1(false)
    })
    console.log(updatePassword)
  }
  

  useEffect(() => {
    let payload = {
      email:email
    }
    setUpdatePassword({...updatePassword, ...payload})
    
  }, []);

  return (
    
      <div className="row">
        {step1 == 1 && (
          <>
            <div className="text-center mb-4 text-light">6 Digit Code has been sent to your Email</div>
            <div className="col-md-12">
              <div className="form-group">
                <label className={`${editprofile.label}`}  htmlFor="exampleFormControlInput1">OTP</label>
                <input
                  onChange={handleChange}
                  name="otp"
                  value={updatePassword.otp}
                  type="text"
                  className={`${editprofile.form_control}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter OTP"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className={`${editprofile.label}`}  htmlFor="exampleFormControlInput1">New Password</label>
                <input
                  onChange={handleChange}
                  name="otp"
                  value={updatePassword.password}
                  type="text"
                  className={`${editprofile.form_control}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter New Password"
                />
              </div>
            </div>
            <div className="col-md-12 ">
              <button
                onClick={HandlePaswwordreset}
                className={editprofile.form_button}
              >
                {loading ? "Updating..." : "Update Password"}
              </button>
            </div>
          </>
        )}

        {step1 == 0 && (
          <>
            {/* <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Confirm  Your Email</label>
                <input
                  onChange={handleChange}
                  name="email"
                  value={updatePassword.email}
                  type="text"
                  className={`${editprofile.form_control}`}
                  id="exampleFormControlInput1"
                  placeholder="Email"
                />
              </div>
            </div> */}
            <div className="col-md-12 ">
              <button
                onClick={requestOtp}
                className={editprofile.form_button}
              >
                {loading1 ? "Requesting..." : "Request OTP"}
              </button>
            </div>
          </>
        )}
      </div>
   
  );
};

const Profile = ({ name, phone, email = '', dob = "" , setProfileSettings}) => {
  const [updateProfile, setUpdateProfile] = useState({
    name: "",
    gender: "",
    dob: "",
    phone: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  
  const [gender, setGender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(true);
  const [error, setError] = useState('')
  const selecteGender = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    if (e.target.value == "male") {
      setGender("male");
      setUpdateProfile({ ...updateProfile, [name]: value });
    }
    if (e.target.value == "female") {
      setGender("female");
      setUpdateProfile({ ...updateProfile, [name]: value });
    }
  };

  useEffect(() => {
    UpdateUser();
  }, []);

  const UpdateUser = () => {
    const user = {
      name: name,
      phone: phone,
      dob: dob
    };

    setUpdateProfile({ ...updateProfile, ...user });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdateProfile({ ...updateProfile, [name]: value });
  };

 const  dateChange = (e) =>{
      {/* 2021-09-24 */}
    const date = moment(e).format('YYYY-MM-DD')
    console.log(date)
    const dateObj = {dob:date}
    console.log(dateObj)
    
    setUpdateProfile((updateProfile) => updateProfile.dob = dateObj)
    

  }

 
  const getToken = () => {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };
  const submitChange = (e) => {
    const { token } = isAuthenticated()
    
    setLoading(true);
    e.preventDefault();
    const config = getToken();
    console.log(updateProfile);
    axios
      .post(
        `${process.env.REACT_APP_AUTH_URL}/update-user-profile`,
        { ...updateProfile },
        config
      )
      .then((res) => {
        setLoading(false);
       const user =  JSON.parse(localStorage.getItem('user'))
       const userObject = res.data.obj
       console.log(user)
       user = {...user, ...userObject}
       console.log(user, 'uchechukwu')
        // console.log(res.data);
        // closeSettings();
          setProfileSettings(false)
        // console.log(res.data.obj)
        // const user = res.obj
        // user.token = token
        // const userData = {...user}
        // console.log(userData)
        
       // localStorage.setItem("user", JSON.stringify(response.data.obj));
        if (res.data.status == 0) {
          message.success({
            content: `${res?.data?.desc},`,
            duration: 3,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        setError(err?.response?.data?.error || "connection error")

        setTimeout(() => {
            setError('')
        },3000)
        message.error({
          content: err?.response?.data?.error || "connection error",
          duration: 3,
        });
      });
    // console.log(res, 'ANSWER')
  };

  return (
    <div className="container position-relative">
     {
       error && (
        <div style={{height: '29px',
    padding: '0',
    width: '86%',
    top: '-47px'}} className="text-center alert alert-danger position-absolute">{error}</div>
       )
     }
      
      <div className="d-flex justify-content-start">
        
        <div className={`${editprofile.edit_profile} `}>{step ? 'Update Profile': 'Update Password'}</div>{" "}
        
      </div>
      <div className="d-flex justify-content-end">
      <button 
        onClick={()=>setStep(!step)}
          style={{
            maxWidth: "113px",
            fontSize: "14px",
            height: "38px",
            color: "var(--humber-link)",
            background: "none",
          }}
          className={editprofile.form_button}
        >
          {step ? 'Edit Password' : 'Edit Profile'}
        </button>
      </div>
     {
       step == true && (
        <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className={`${editprofile.label}`} htmlFor="exampleFormControlInput1">Full Name</label>
            <input
              onChange={(e) => {
                handleChange(e);
              }}
              name="name"
              value={updateProfile.name}
              type="email"
              className={`${editprofile.form_control}`}
              id="exampleFormControlInput1"
              placeholder=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className={`${editprofile.label}`}  htmlFor="exampleFormControlInput1">Phone</label>
            <input
              onChange={handleChange}
              name="phone"
              value={updateProfile.phone}
              type="text"
              className={`${editprofile.form_control}`}
              id="exampleFormControlInput1"
              placeholder=""
            />
          </div>
        </div>
        {/* <div className={`col-md-6 col-sm-6 col-xs-6 ${editprofile.gender}`}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Gender</label>
            <input
              name="gender"
              onClick={selecteGender}
              type="button"
              className={`${editprofile.form_control} ${
                gender == "male" ? editprofile.selected_gender : ""
              }`}
              id="exampleFormControlInput1"
              placeholder=""
              value="male"
            />
          </div>
        </div> */}
        {/* <div className={`col-md-6 col-sm-6 col-xs-6 ${editprofile.gender}`}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"></label>
            <input
              name="gender"
              onClick={selecteGender}
              type="button"
              className={`${editprofile.form_control} ${
                gender == "female" ? editprofile.selected_gender : ""
              }`}
              id="exampleFormControlInput1"
              placeholder=""
              value="female"
            />
          </div>
        </div> */}
        {/* <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Date Of Birth</label>
            
            <DatePicker selected={updateProfile.dob} onChange={dateChange} className={`${editprofile.form_control}`}/>
            
          </div>
        </div> */}

        <div className="col-md-12 ">
          <button onClick={submitChange} className={editprofile.form_button}>
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
       
      </div>
       )
     }

     {
       step == false && (
        <PasswordChange setProfileSettings={setProfileSettings} email={email} />
       )
     }
    </div>
  );
};

export default Profile;
// export default EditProfile;
