import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { useQuery } from "react-query";
import Stack from "../../components/Stack";
import GuessHeader, { GuessCategoryList } from "./GuessHeader";
import Guess from "./Guess";
import newguess from "./newguess.module.css";
import questionmark  from '../../assets/gulder_assets/guess_logo.png'

import brand from "../../assets/svg/brand.svg";

import diary from "../../assets/svg/diary.svg";
import eviction from "../../assets/svg/eviction.svg";
import hoh from "../../assets/svg/hoh.svg";
import nomination from "../../assets/svg/nomination.svg";
import party from "../../assets/svg/party.svg";
import Countdown from "antd/es/statistic/Countdown";
// import CountDown from 'ant-design-pro/lib/CountDown'
import { message } from "antd";
import Spinner from "../../components/Spinner";
import Payments from "../../components/Payments";
import Modalb from "../../components/Modal";
import {  Modal} from 'react-bootstrap'
import  Spinner2  from 'react-bootstrap/Spinner'
import moment from 'moment'
import CountTime from './countDown.jsx'
import  { isAuthenticated } from '../../utils/authdata'
import successcheck from "../../assets/svg/successcheck.svg";
import SweetAlert from 'react-bootstrap-sweetalert';
import FeatherIcon from "feather-icons-react"
import { useFetchStat } from "../../utils/useFetchStat";
import Activities from "../../components/Activities/activities";
import { StatsContext } from '../../context/'

const calculateTimeLeft = (end, start) => {
  const difference = +new Date(end) - +new Date(start);

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  console.log("the time left", timeLeft);
  return `${timeLeft.days} d - ${timeLeft.hours} h - ${timeLeft.minutes} m - ${timeLeft.seconds} s `;
};

// spinner for answer submittion
const Bspinner = () => {
  return (
  <Spinner2 animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner2>
  )
}


function onFinish() {
  console.log("finished!");
}

const GuessTimer = ({time}) =>{
  return (
    <>
       
         
    </>
  )
}
const NewGuess = () => {
  const [mymyToken, setMymyToken] = useState(null);
  const [modalView, setClose] = useState(null);
  const tokenObj = JSON.parse(localStorage.getItem("user")) || "";
  const [Alert, ShowAlert] = useState(false);
  const { token } = isAuthenticated()

  const fetchUserStat = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_URL}/api/v1/user-stat`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMymyToken(response?.data?.TOKEN);
      console.log("hkbskskvjsf", response?.data?.TOKEN);
    } catch (error) {
      console.log("from user stats error is >>", error);
    }
  };
  useEffect(() => {
    fetchUserStat();
    const newEvent = (event) => {
      setClose(event);
    };
    
  }, []);

  console.log("wallet balance is >>>", mymyToken);
  const ourTokenToken = tokenObj.token;
  const CatGegories = ({ guessCategoryId, guess, catIcons, id }) => {
    console.log(guess)
    // console.log(guess, 'uchech' )
    return (
      <div className={newguess.card} onClick={() => handleShowGuess(guess.id, guess)}>
        {guessCategoryId?.name === "all" ? (
          ""
        ) : (
          <img src={guess ? guess?.imgUrl : catIcons[id]} alt="icons" />
        )}
        <div className={newguess?.description}>
          <div className={newguess?.h3}>{guess?.name}</div>
         
          <div style={{color:"var(--humber-link)"}}>Click to view <FeatherIcon icon="chevron-right"/></div>
         
        </div>
      </div>
    );
  };

  const history = useHistory();

  const [guessgames, setGuessGames] = useState({});
  const [catIcons, setCatIcons] = useState([diary, eviction, hoh, party]);
  const [sessionData, setSessionData] = useState();
  // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);

  

  // const {data, status } = useQuery('gameSessions', fetchGameSession)
  const [response, setReponse] = useState(0);
  const [typeofoption, setTypeOfOption] = useState("obj"); // obj or input
  const [showguess, setShowGuess] = useState(false);
  const [pickoption, setPickOption] = useState(false);
  const [showguesscategory, setShowGuessCategory] = useState(true);
  const [random, setRandom] = useState(getRandomInt(7));

  const [optionId, setOptionId] = useState("");
  const [guessId, setGuessId] = useState("");
  const [optionAnswer , setoptionAnswer] = useState("")
  const [freeText, setFreeText] = useState({
    guessId: '',
    userAnswerText: ''
  });


    

  const [eachGuess, setEachGuess] = useState([]);
  const [feedBackText, setFeedBackText] = useState("");
  const [feedBackCoin, setFeedBackCoin] = useState("");
  const [pickedSelection, setPickedSelection] = useState();
  const [topUp, setTopUp] = useState(false);
  const [submitAnswser, setSubmitAnswer ] = useState(false)
  const [key, setKey] = useState(1)
 
  const { state:{stat} } = useContext(StatsContext)
  
  const [timer, SetTimer ] = useState(null)
  

  const againtext = random > 3 ? "Avatar" : "Trivia";
  const againurl = againtext === "Avatar" ? "/avatars" : "/trivia-game";

  const headers = {
    Authorization: token,
  };
  
  const fetchGameSession = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GUESS_GAME_URL}/guess-game-category`, 
        { headers }
      );
      console.log("response from the fetch game session latest", data);
      setSessionData(data?.obj?.guessCategory);
      console.log(data.obj.guessCategory,'game');
      setLoading(false);
      // return data
    } catch (error) {
      console.log("the error is", error);
      // return error
      setLoading(false);
      if(error instanceof Error){
        if(error.message == "Network Error"){
          message.error('Network error, please check your network')
        }
      }
      if(error.response){
        message.error(error.response.desc)
      }
    }
  };

  const fetchGuessGameById = async (gameId) => {
    
    console.log("the game id been sent is >>", gameId);
    setLoading1(true)
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GUESS_GAME_URL}/guess-game/random/${gameId}`,
        { headers }
      );
      console.log("data got fetching guess game by guess game id", data);
      setGuessGames(data?.obj);
      setLoading1(false)
      if(data.status == 0){
        if(data.desc === 'Unauthorized access'){
          message.error(data.desc + ', You need to login again')
        }
        
      }
    } catch (error) {
     
      setLoading1(false)
      if(error instanceof Error){
        if(error.message == "Network Error"){
          message.error('Network error, please check your network')
        }
      }
      if(error.response){
        message.error(error.response.desc)
      }
      console.log("error trying to fetch guess games by id >>>", error);
    }
  };

  useEffect(() => {
    fetchGameSession();
  }, []);

  const handleShowGuess = (id, guess) => {
    
    // SetTimer(guess.endDateTime)
    // setShowGuessCategory(false);
    // setShowGuess(true);
    fetchGuessGameById(id);
    // setShowGuess(false);
    setShowGuessCategory(false);
    setPickOption(true);
  };
  const handleOptions = (id) => {
    // alert(`${id}`)
    const res = guessgames.filter((event) => event.id === id);
    
    setEachGuess(res);
    setShowGuess(false);
    setPickOption(true);
  };
  const handleSubmitGuess = () => {
    setPickOption(false);
    setReponse(1);
  };
  const playagain = () => {
    setReponse(0);
    setShowGuessCategory(true);
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }





  const freeTextSubmit = async (each) => {
    const {guessId, userAnswerText} = freeText
    setLoading(true)
    if(!userAnswerText){
      message.error({
        content: "Please fill in the answer input",
        duration: 5,
      });
      setLoading(false)
    }
   
   else if (mymyToken < 10) {
      // setSubmitAnswer2(false)
      message.error({
        content: "You need to have tokens to play Guess Game",
        duration: 5,
      });
      setTopUp(true);
      setLoading(false)
     
    }
    // alert(`${guessId} for free text`)
    else{
      
      try {
        // setSubmitAnswer2(false)
        let payload={
          guessId: guessId,
          userAnswerText: userAnswerText
        }
        const response = await axios.post(
          `${process.env.REACT_APP_GUESS_GAME_URL}/guess-game-answer`,payload,
          // {...freeText},
          { headers }
        );
        message.success(`${response?.data?.desc} recorded succesfully`);
        setFeedBackText(response?.data?.obj.userAnswerText);
        setFeedBackCoin(response?.data?.obj.coinAward);
        console.log(
          "response from submit guess with free text",
          response?.data
        );
        setLoading(false)
        handleSubmitGuess();
        setKey(key=>key + 1)
      } catch (error) {
        setLoading(false)
        console.log(error?.response?.data);
        if(error?.message == 'Network Error'){
          message.error({ content: "Network error", duration: 5 });
        }
        else if(error?.response?.status == 500){
          message.error({ content: "Internal server error", duration: 5 });
        }else if(error?.response?.status == 404){
          message.error({ content: "Error occured while playing games, please try again", duration: 5 });
        }else {
          message.error({ content: error.response.data.desc, duration: 5 });
        
        }
       
        
        
       
        // message.error(error?.response?.data || 'connection error')
      }
    } 
    
  };

  const optionsSubmit = async () => {
    setSubmitAnswer(true)
    if (mymyToken < 10) {
      setSubmitAnswer(false)
      message.error({
        content: "You need to have tokens to play Guess Game",
        duration: 5,
      });
      setTopUp(true);
      return;
    }
    if (guessId && optionId && optionAnswer ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_GUESS_GAME_URL}/guess-game-answer`,
          {
            guessId: guessId,
            optionId: optionId,
            userAnswerText:optionAnswer
          },
          { headers }
        );
        // message.success(response.data)
        setSubmitAnswer(false)
        console.log("response from submit guess with options", response);
        handleSubmitGuess();
        setKey(key=>key + 1)
      } catch (error) {
        if(error instanceof Error) {
          if(error.message === 'Network Error'){
            message.error(
                "connection error, Please try again later"
            );
          }
        }
        if(error?.response){
          if(error?.response?.status === 404){
            message.error(
             "An error occured, please try again"
            );
          }
          else{
            message.error(
              `${error?.response?.data?.desc}`
                
            );
          }
        }
        setSubmitAnswer(false)
        
      
      }
    } else {
      message.error({ content: "you have to make a selection", duration: 5 });
      setSubmitAnswer(false)
      // alert('you have to make a selection')
    }
  };

  const handleOptionsPicked = (optionId, guessId, optionText) => {
    console.log(optionText)
    setoptionAnswer(optionText)
    // alert(`${optionId}`)
    setOptionId(optionId);
    setGuessId(guessId);
    setPickedSelection(optionId);
  
  };

  return (
    <div
      style={{
        width: "90%",
        paddingBottom: "50px",
        minHeight: "100vh",
        margin: "50px auto",
      }}
      className="zoom"
    >
    
    { showguesscategory && (<div className='page-title'><a type='button' onClick={()=>history.goBack()}><FeatherIcon icon="arrow-left"/></a>  Guess Game</div>)}
      <div className={newguess?.hide}>
      
      
      <Activities key={key} stat={stat} />
      </div>
      <GuessHeader setGuessGames={setGuessGames} setShowGuessCategory={setShowGuessCategory}
    setPickOption={setPickOption} pickoption={pickoption}>{false && <GuessCategoryList />} {pickoption && (<Countdown
          
          value={guessgames?.endDateTime}
          onFinish={onFinish}
          valueStyle={{ color: "#cf1322" }}
        />) }</GuessHeader>
     

      {showguesscategory && (
        <div className={newguess.guesscategory_wrapper}  style={{ padding: "20px 23px" }}>
          <div className={newguess.imgholder}>
            <img style={{width:'80px'}} src={questionmark} alt="questionmark" />
          </div>

          {
            sessionData && (
              <div className={newguess.select}>
            Select a category to start your game now
          </div>
            )
          }
         
          <div className={newguess.categorywrapper}>
            {loading ? (
              <div
                style={{
                  justifyContent: "center",
                  marginTop: "100px",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Spinner />
              </div>
            ) : sessionData ? (
              sessionData.map((guess, id) => {
                
                
                return (
                  <CatGegories

                    key={guess?.id}
                    catIcons={catIcons}
                    
                    id={id}
                    guess={guess}
                    // guessCategoryId={guessCategoryId}
                  />
                );
              })
            ) : (
              <div
                style={{
                  justifyContent: "center",
               
                  width: "100%",
                  display: "flex",
                }}
              >
                {/* <Spinner /> */}
                <h3 style={{fontSize:'1rem'}}>No  guess game</h3>
              </div>
            )}
          </div>
        </div>
      )}

    

      {pickoption && (
        <div className={newguess.answerbox}>
         
          <img src={questionmark} alt="questionmark" />
            {
              loading1 ? (
                <div className="d-flex mt-4 mb-3 justify-content-center">
                <Bspinner />
                </div>
              ) : (
                !guessgames ? (
                  <div style={{color:"var(--humber-light)"}} className="mb-4 mt-3">
                  Questions not available for this category
                </div>
                ) : (
                  <>
                  <div className={`${newguess.answertext} mb-5 mt-4`}>
          
          {guessgames?.questionText}
        </div>
        {
            guessgames?.answerType === 'FREE_TEXT' && (
            <>
            
                  <input
                    type="text"
                    name="answer"
                    placeholder=""
                    className={newguess.answerinput}
                    
                   
                    onChange={(e) =>
                      setFreeText({
                        guessId: guessgames?.id,
                        userAnswerText: e.target.value,
                      })
                    }
                  />
                  <p className={newguess.highlight}>(Type in your Guess)</p>
                  <div className={`${newguess.button_wrapper} d-flex`}
                    style={{
                      
                      
                    }}
                  >
                    <button
                      className={newguess.btn}
                      onClick={() => freeTextSubmit(guessgames)}
                    >
                   
                    {
                      loading ?  <Bspinner /> : 'SUBMIT GUESS'
                    }
                    </button>
                  </div>
                </>
            )
          }
            {/* {JSON.stringify(guessgames)} */}
          {
              guessgames?.answerType === "OPTIONS" && (
                <>
                  <div className={newguess.optionswrapper}>
                    {guessgames?.guessOptionList?.map((options) => (
                      <div 
                        key={options?.id}
                        style={{ cursor: "pointer" }}
                        className={`${newguess.options} ${
                          options.id === pickedSelection
                            ? newguess.activeoptions
                            : ""
                        } `}
                        onClick={() =>
                          handleOptionsPicked(options?.id, guessgames?.id, options?.optionText)
                        }
                      >
                        {options.optionText}
                      </div>
                    ))}
                  </div>
                  <div
                     className={`${newguess.button_wrapper} d-flex`}
                  >
                    <button
                      className={newguess.btn}
                      onClick={() => optionsSubmit()}
                      // style={{ marginLeft: "auto" }}
                    > 
                    {
                      submitAnswser ?  <Bspinner /> : 'SUBMIT GUESS'
                    }
                      
                     
                    </button>
                  </div>
                </>
              )
          }
                  </>
                )
              )
            }
           
         
        

         

          
        </div>
      )}

      <div
        style={{ padding: "20px 0", display: "flex", justifyContent: "center" }}
      >
        {response === 2 && (
          <div className={newguess.alreadyguessed}>
            <div className={newguess.guesserror}>
              You already made a Guess, mind trying other guesses?
            </div>
            <button className={newguess.btn}>New Guess</button>
          </div>
        )}

        {response === 1 && (
          
          <>
          <Modal dialogClassName="guessGame" size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={true}>
            <div className={newguess.alreadyguessed}>
            <img
              src={successcheck}
              style={{ width: '65px'}}
              alt="successcheck"
            />
            <div className={newguess.guessmade}>Guess Recorded</div>
            <div className={newguess.guessdesc}>
              Count down to review result  <Countdown
          // timer
            // title="Countdown"
            value={new Date(guessgames?.endDateTime)}
            onFinish={onFinish}
            //  format="H 时 m 分 s 秒"
            valueStyle={{ color: "#cf1322" }}
          />
            </div>
            {/* <div>Result will be announced in 10hrs</div> */}
            {/* {feedBackText && <div>
              Your guess was: : {feedBackText}</div>} */}

            {/* {feedBackCoin && <div>
              coin awarded is : {feedBackCoin }
            </div>} */}

            <div className={`${newguess.btnwrapper} mb-3`}>
              <button className={newguess.smallbtn} onClick={playagain}>
                {" "}
                Play Again{" "}
              </button>
              <button
                onClick={() => history.push("/dashboard")}
                // onClick={() => history.push(againurl)}
                className={`${newguess.smallbtn} ${newguess.nobtn}`}
              >
                {/* Play {againtext}{" "} */}
                Quit
              </button>
            </div>
          </div>
           
          </Modal>
         
       
          </>
        )}
      </div>
      {topUp && (
        <Modalb setTopUp={setTopUp}
          closeModal={() => setTopUp(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <Payments />
        </Modalb>
      )}
    </div>
  );
};

export default NewGuess;
