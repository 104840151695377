import React, { useState, useEffect , useContext} from "react";
import { Link } from "react-router-dom";
import newprofile from "./newprofile.module.css";
import MyPoints from "../../components/Stack/MyPoints";
import Position from "../../components/Stack/Position";
import MyMyPoints from "../../components/Stack/MyMyPoints";
import Stack from "../../components/Stack";
import EachAvatar from "../../components/EachAvatar";
import EditProfile from "../../components/EditProfile";

import myavatar from "../../assets/svg/myavatar.svg";
import settings from "../../assets/svg/settings.svg";
import lock from "../../assets/svg/lock.svg";
import camryhybrid from "../../assets/svg/camryhybrid.svg";
import redeemright from "../../assets/svg/redeem-right.svg";
import redeemleft from "../../assets/svg/redeem-left.svg";
//import Modal from "../../components/Modal";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useFetchStat } from "../../utils/useFetchStat";
import SleekSlider from "../../components/SleekSlider";
import CustomModal from '../../components/CustomModal/CustomModal';
import FeatherIcon from "feather-icons-react"
import  { isAuthenticated } from '../../utils/authdata'
import Activities from "../../components/Activities/activities";
import { StatsContext } from '../../context/'
import { useHistory } from "react-router";

const GameTrans = (props) => {
  // const { stats } = props

  const { title, color, total, fail, win, trivia } = props;

  return (
    <div
      className={`${newprofile.gametrans} ${
        color === "guess" ? newprofile.guessbg : newprofile.triviabg
      }`}
    >
      <div
        className={` d-flex justify-content-between ${newprofile.title} ${
          color === "guess" ? newprofile.guesscolor : newprofile.triviacolor
        }`}
      >
        {title}
        <Link
          className="position-relative"
          style={{ fontSize: "11px" }}
          to="/history"
        >
          My Results{" "}
          <span
            className={`dot bg-danger position-absolute ${newprofile.history_total}`}
          >
            {total ? total : "0"}
          </span>
        </Link>
      </div>
      <div style={{ padding: "5px 0", lineHeight: "1.5rem" , color:"var(--humber-light)"}}>
        <div>
          <strong>Stats</strong>
        </div>
        <div>
          Correct {trivia ? "Answer(s)" : "Guesses"}: &nbsp;&nbsp;{" "}
          {win ? win : "0"}{" "}
        </div>
        <div>
          Wrong {trivia ? "Answer(s)" : "Guesses"}: &nbsp;&nbsp;{" "}
          {fail ? fail : "0"}
        </div>
        <div>Total: &nbsp;&nbsp; {total ? total : "0"}</div>
      </div>
    </div>
  );
};

const NewProfile = (props) => {
  const { guessStats, triviaStats, userCoins, userPoints } = useFetchStat();
  const [profileSettings, setProfileSettings] = useState(false);
  const [user, setUserData] = useState({});
  const tokenObj = JSON.parse(localStorage.getItem("user")) || "";
  const { state:{stat} } = useContext(StatsContext)
  const history = useHistory()
  const formatNumber = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUserData(userData);
    // fetchUserStat()
    fetchTrivialStat();
  }, []);

  // const fetchUserStat = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_AUTH_URL}/api/v1/user-stat`,
  //         {
  //           headers: { Authorization: `Bearer ${tokenObj.token}` },
  //         }
  //       );
  //     //   setMymyToken(response?.data?.TOKEN);
  //       console.log("hkbskskvjsf", response?.data?.TOKEN);
  //     } catch (error) {
  //       console.log("from user stats error is >>", error);
  //     }
  //   };

  const fetchTrivialStat = () => {
    const { token } = isAuthenticated()
    const data = JSON.parse(localStorage.getItem("user"));
   

    axios
      .post(
        `${process.env.REACT_APP_GUESS_GAME_URL}/trivia-game-answer/game-stat`,
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const openSettings = () => {
    setProfileSettings(settings);
  };
  const closeSettings = () => {
    setProfileSettings(!settings);
  };
  return (
    <div>
      <Modal
        close
        centered
        size="lg"
        dialogClassName="profile_modal"
        show={profileSettings}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setProfileSettings(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <EditProfile
            setProfileSettings={setProfileSettings}
            name={user.name}
            email={user.email}
            phone={user.phone}
            closeSettings={closeSettings}
          />
        </Modal.Body>
      </Modal>
      <div style={{
        width: "90%",
        paddingBottom: "50px",
        minHeight: "100vh",
        margin: "50px auto",
      }}>
   <div className='page-title'> <FeatherIcon type="button" size="20px" onClick={()=>history.goBack()} icon="arrow-left"/> My Profile</div>

      <div className={newprofile.newprofilewrap}>
        <section className={newprofile.sectone}>
          <div className={newprofile.bio}>
            <div className={newprofile.biohead}>
              <img src={settings} alt="settings" onClick={openSettings} />
            </div>
            <div className={newprofile.biosub}>
              <div className={newprofile.bioone}>
                {/* <img src={myavatar} alt="myavatar" /> */}
                <h1 className={newprofile.avatar}>{user?.name?.[0]}</h1>
              </div>
              <div className={newprofile.biotwo}>
                <div>
                  Name:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {user.name}
                  </span>
                </div>
                <div>
                  Email:&nbsp;&nbsp;&nbsp;&nbsp; <span>{user.email}</span>{" "}
                </div>
                <div>
                  Phone:&nbsp;&nbsp;&nbsp;&nbsp; <span>{user.phone}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={newprofile.transactions}>
            {/* <div style={{fontSize: '20px', fontWeight: 'bold'}}>Transaction</div> */}
            <div style={{ padding: "20px 0" }} className="row">
              <div
                className={`${newprofile.first}  ${newprofile.col_class} col-md-6 col-sm-12 col-xs-12  `}
              >
                <Link
                  className={`text-center ${newprofile.link_history}`}
                  style={{
                    color: "var(--humber-golden)",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  className=""
                  to="/history"
                >
                  View Play History <FeatherIcon icon='chevron-right' size="19px" color="var(--humber-light)"/>
                </Link>
              </div>
              <div
                className={` ${newprofile.col_class} col-md-6 col-sm-12 col-xs-12  `}
              >
                <Link
                  className={`${newprofile.link_history} text-center`}
                  style={{
                    color: "var(--humber-light)",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  className=""
                  to="/transactions"
                >
                  View Transactions <FeatherIcon icon='chevron-right' size="19px" color="var(--humber-light)"/>
                </Link>
              </div>
            </div>
          </div>
          <Activities stat={stat}  pagename={'profile'}/>
          {/* <div style={{ marginTop: "30px" }}>
            <MyPoints />
          </div>
          <div className={newprofile.collect}>
            <div className={newprofile.collectone}>
              <Position />
            </div>
            <div className={newprofile.collectone}>
              <MyMyPoints />
            </div>
          </div> */}
        </section>
        <section className={newprofile.secttwo}>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ color: "#123873", fontWeight: "bold", fontSize: "20px" }}
            >
              My Avatars
            </div>
            <div
              style={{ color: "#0A7815", fontWeight: "bold", fontSize: "16px" }}
            >
              View History
            </div>
           
          </div> */}
          {/* <div className={newprofile.avlist}>
            <EachAvatar /> 
            <EachAvatar />
            <EachAvatar />
          </div> */}

          <div className={newprofile.guessandtrivia}>
            <GameTrans {...guessStats} title="Guess Game" color="guess" />
            <GameTrans trivia {...triviaStats} title="Trivia" color="trivia" />
          </div>
          {/* redeem */}
          <div className={newprofile.redeem}>
            <div className={newprofile.rdbox}>
              <div >
                You have <strong>{userCoins ?  formatNumber(userCoins) : '0'} coins</strong>{" "}, {" "}
                {userCoins && userCoins < 1000 || !userCoins ? `earn more coins and redeem any of this prizes`: ` already will you like to redeem any of the prizes with it?`}
              </div> 
              {/* <div style={{ margin: "20px 0" }}>
                <img
                  src={lock}
                  alt="lock"
                  title="lock"
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "45%",
                    cursor: "pointer",
                  }}
                />
                <img src={camryhybrid} alt="camryhybrid" title="camryhybrid" />
                <img
                  src={redeemright}
                  alt="camryhybrid"
                  title="camryhybrid"
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: "0",
                    cursor: "pointer",
                  }}
                />
                <img
                  src={redeemleft}
                  alt="camryhybrid"
                  title="camryhybrid"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "0",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div>
                <strong>{userPoints ? userPoints : 0} points</strong>
              </div> */}
              <SleekSlider userPoints={formatNumber(userPoints)} />
              {/* <Link to="/rewards-prizes">View Prizes</Link> */}
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  );
};

export default NewProfile;
