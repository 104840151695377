import React, { useState, useEffect , useContext } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import Scrambleheader from "./ScrambleHeader";

import scramble from "./scramble.module.css";
import questionmark from "../../assets/svg/question-mark.svg";
import scramble_logo from '../../assets/gulder_assets/scramble.png'

import Countdown from "antd/es/statistic/Countdown";
import Spinner2 from "react-bootstrap/Spinner";
import moment from "moment";
import { isAuthenticated } from "../../utils/authdata";
import TriviaSection from './scramble_section'
import Activities from "../../components/Activities/activities";
import { StatsContext } from '../../context/'

import { Wrapper, QuestionBox, Rowwrapper, Letterbox } from "./scramble_style";

// spinner for answer submittion
const Bspinner = () => {
  return (
    <Spinner2 animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner2>
  );
};

function onFinish() {
  console.log("finished!");
}

const WordSramble = () => {
  const { token } = isAuthenticated();
  const history = useHistory();
  const [showScramble, setShowScramble] = useState(true)
  const [value, setValue] = useState('')
  const headers = {
    Authorization: token,
  };
  const [word, setWord] = useState('')
  const { state:{stat} } = useContext(StatsContext)
  const handleChange = (event) => {
      const { value} = event.target
      setValue(value)
  }

  const handleSubmit = (event) =>{
    
    
const res =  value.split("").some(f=> f === 'word');
console.log(res);
  }
  const ScrambleWord = () => {
    const words = ['andrew', 'humber', 'maxwell', 'mitchel', 'chidiebere', 'demola', 'emmanuel', 'tunder', 'celestina', 'princess']
    const sortWord = words.sort((a,b)=> 0.5 - Math.random())
    const array = []
    sortWord.forEach(item => {
      
      const temp = item.split("")
      temp.sort((a,b)=> 0.5 - Math.random())
      
      const temp1 = temp.join("")
     
      array.push(temp1)
      
      
    })
    
    const rndInt = Math.floor(Math.random() * words.length -1) + 1
    
    setWord(array[rndInt])
    
  }

  useEffect(() => {
    ScrambleWord()
  }, [])
  return (
    <Wrapper>
    
      <div className={scramble?.hide}>
        <div className="page-title">Scramble Game</div>
       <Activities stat={stat} />
      </div>
      <Scrambleheader>
        {false && (
          <Countdown
            value={""}
            onFinish={onFinish}
            valueStyle={{ color: "#cf1322" }}
          />
        )}
      </Scrambleheader>
      {showScramble && (
        <div style={{backgroundColor:'var(--humber-black)', padding:'50px', border:'1px solid #f5e69747'}} className="mt-3">
        <TriviaSection setShowScramble={setShowScramble}/> 
        </div>
      )}
      
      {!showScramble && (
        <QuestionBox>
          <img src={scramble_logo} alt="questionmark" />
          {false ? (
            <div className="d-flex mt-4 mb-3 justify-content-center">
              <Bspinner />
            </div>
          ) : false ? (
            <div style={{ color: "var(--humber-light)" }} className="mb-4 mt-3">
              Questions not available for this category
            </div>
          ) : (
            <>
              <div className={`${scramble.answertext} mb-2 mt-4`}>Unscrambe the Word:</div>
              <Rowwrapper>
              {
                word.split("").map((word)=>(
                  <>
                  <Letterbox >
                        <span>{word}</span>
                  </Letterbox>
                  </>
                ))
              }
                    {/* <Letterbox >
                        <span>s</span>
                    </Letterbox>
                    <Letterbox >
                        <span>d</span>
                    </Letterbox>
                    <Letterbox >
                        <span>s</span>
                    </Letterbox> */}
                    
                </Rowwrapper>
              <input onChange={handleChange} type="text" name="answer" placeholder="" />
                
              <Rowwrapper>
                <button onClick={handleSubmit} className={scramble.btn}>
                  {false ? <Bspinner /> : "SUBMIT "}
                </button>
                <button onClick={ScrambleWord}>NEXT</button>
              </Rowwrapper>
            </>
          )}
        </QuestionBox>
      )}
    </Wrapper>
  );
};

export default WordSramble;














// import { message } from "antd";
// import Spinner from "../../components/Spinner";
// import Payments from "../../components/Payments";
// import Modalb from "../../components/Modal";
// import { Modal } from "react-bootstrap";
// import successcheck from "../../assets/svg/successcheck.svg";

// import FeatherIcon from "feather-icons-react";
// import { useFetchStat } from "../../utils/useFetchStat";