import React, {useState} from 'react'
import Navbar from './MainMenu/Navbar';
// import avatar from '../../assets/images/avatar.svg';
// import trivia from '../../assets/images/triviaicon1.png'
// import guess from '../../assets/images/guess-1.svg';
import avatar from '../../assets/gulder_assets/avatar.svg';
import trivia from '../../assets/gulder_assets/trivia.png'
import guess from '../../assets/gulder_assets/guess-logo.svg' 
import scramble from '../../assets/gulder_assets/scramble.png'
import gift1 from '../../assets/images/gift-1.svg';
import gift2 from '../../assets/images/gift-2.svg';
import gift3 from '../../assets/images/gift-3.svg';
import gift4 from '../../assets/images/gift-4.svg';
import gift5 from '../../assets/images/gift-5.svg';

import { Container,Wrapper, Row, Column, Header, Text , Card, CardAvatar, Subtitle} from './styles/home2-styles'
import CustomModal from '../../components/CustomModal/CustomModal';
import axios from 'axios'
import { message } from 'antd';
import { authenticate, isAuthenticated } from '../../utils/authdata';
import {useHistory} from 'react-router-dom'
import { validateEmail } from '../../utils/emailValidation';
import FeatherIcon from 'feather-icons-react'


const Home2 = () => {
  
     let history = useHistory()
     const [showModal, setShowModal] = useState(false);
     const [loading, setLoading] = useState(false);
     const [screen, setScreen] = useState(2);

     const {token} = isAuthenticated()

     const [values, setValues] = useState({
         name: '',
         email: '',
         phone : '',
         password: '',
         otp:'',
         errors:{
             nameError : '',
             emailError : '',
             otpError : '',
             phoneError : '',
             passwordError : '',
         }
     })

     const [showPassword, setShowPassword] = useState(false);

     const togglePasswordVisiblity = () => {
      setShowPassword(showPassword ? false : true);
    };
    

     const { name, email, phone, password, otp, errors} = values;

    const handleChange = name =>e=>{
        setValues({...values, errors:{}, [name]: e.target.value})
    }
   
    const loginScreen = ()=>{
        setScreen(1)
    }

    const signupScreen = ()=>{
        setScreen(2)
    }

    console.log("VALUES, values");

    const registerUser = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(!name){
            setLoading(false);
            setValues({...values, errors:{nameError:'Name is required'}})}
        else if(!email.trim()){
            setLoading(false);
            setValues({...values, errors:{emailError:'Email is required'}})
        }
        else if(validateEmail(email)){
            setValues({...values, errors: {emailError:"Please provide a valid email*"}})
            setLoading(false);
          }
        else if(!phone){
            setLoading(false);
            setValues({...values, errors:{phoneError:'Phone is required'}})
        }
        
        else if(!password){
            setValues({...values, errors:{passwordError:'Password is required'}});
            setLoading(false);
        }else if(password.length < 8){
            setValues({...values, errors:{passwordError:'Minimum characters is 8 digits or more '}});
            setLoading(false);
        }else{
          
            try{

                const payload = {
                    name : name,
                    email : email,
                    phone : phone,
                    password: password
                }

               
                const {data} = await axios.post(`${process.env.REACT_APP_AUTH_URL}/register`, payload);

                if(data?.status ===1){
                    message.error(data?.desc);
                    setLoading(false)
                  }    
               else{ 
                    message.success("Six(6) digit code has been sent to your email to complete your registration!");
                    setScreen(4);  
                    setLoading(false)          
            } 
          

            }catch(error){
                  
        if (error instanceof Error) {
            if(error.message === 'Network Error'){
              message.error('Please check your network connection!');
              setLoading(false)  
             }else{
             
             setLoading(false)
             }
      
           }else{
             setLoading(false)
           }
          
            }
        }
    }
    


    const loginUser = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(!email.trim()){
            setLoading(false);
            setValues({...values, errors:{emailError:'Email is required'}})
        }
        else if(validateEmail(email)){
            setValues({...values, errors: {emailError:"Please provide a valid email*"}})
            setLoading(false);
          }
        else if(!password){
            setValues({...values, errors:{passwordError:'Password is required'}});
            setLoading(false);
        }else if(password.length < 8){
            setValues({...values, errors:{passwordError:'Minimum characters is 8 digits or more '}});
            setLoading(false);
        }else{
          
            try{

                const payload = {
                    email : email,
                    password: password
                }
                const {data} = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/login`, payload);

                if(data?.status === 1){
                    message.error(data?.desc);
                    setLoading(false); 
                    if(data?.desc === "Kindly Verify Email"){
                        setScreen(4) 
                    }    
                  }   
               else{
       
               
                 
                    message.success("Login successful!");

                 setShowModal(false);
               
                 authenticate(data?.obj, ()=>{
                    history.push('/dashboard');
                  })
                 
          } 
          

            }catch(error){
                  
        if (error instanceof Error) {
            if(error.message === 'Network Error'){
              message.error('Please check your network connection!');
              setLoading(false)  
             }else{
             
             setLoading(false)
             }
      
           }else{
             setLoading(false)
           }
          
            }
        }
    }

    const completeRegistration = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(!otp){
            setLoading(false);
            setValues({...values, errors:{otpError:'OTP is required'}})
        }else{
          
            try{

                const payload = {
                    email : email,
                    otp : otp,
                }
                const {data} = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/email/verify`, payload);

                if(data?.status ===1){
                    message.error(data?.desc);
                    setLoading(false)
                  }    
               else{   
                    message.success("Registration successful!");
                    setShowModal(false);
                    authenticate(data?.obj, ()=>{
                        history.push('/dashboard');
                      })
                  
                 
          } 
          
            }catch(error){
                  
        if (error instanceof Error) {
            if(error.message === 'Network Error'){
              message.error('Please check your network connection!');
              setLoading(false)  
             }else{
             
             setLoading(false)
             }
      
           }else{
             setLoading(false)
           }
          
            }
        }
    }

    
    const forgotPassword = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(!email.trim()){
            setLoading(false);
            setValues({...values, errors:{emailError:'Email is required'}})
        }else if(validateEmail(email)){
            setValues({...values, errors: {emailError:"Please provide a valid email*"}})
            setLoading(false);
          }
        else{
          
            try{

                const payload = {
                    email : email,
                }
                const {data} = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/reset`, payload);

                if(data?.status ===1){
                    message.error(data?.desc);
                    setLoading(false)
                  }    
               else{   
                message.success("Six(6) digit code has been sent to your email to reset your password!");
                   setScreen(5)
                   setLoading(false)
              } 
          
            }catch(error){
                  
        if (error instanceof Error) {
            if(error.message === 'Network Error'){
              message.error('Please check your network connection!');
              setLoading(false)  
             }else{
             
             setLoading(false)
             }
      
           }else{
             setLoading(false)
           }
          
            }
        }
    }


    const confirmReset = async(e)=>{
        e.preventDefault();

        setLoading(true);
        if(!email.trim()){
            setLoading(false);
            setValues({...values, errors:{emailError:'Email is required'}})
        }else if(validateEmail(email)){
            setValues({...values, errors: {emailError:"Please provide a valid email*"}})
            setLoading(false);
          }
        else if(!otp){
            setLoading(false);
            setValues({...values, errors:{otpError:'OTP is required'}})
        }
        else if(!password){
            setLoading(false);
            setValues({...values, errors:{passwordError:'Password is required'}})
        }
        else if(password.length < 8){
            setLoading(false);
            setValues({...values, errors:{passwordError:'Minimum characters is 8 digits or more '}})
        }else{
          
            try{

                const payload = {
                    email : email,
                    otp : otp,
                    password:password
                }
                const {data} = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/reset-password`, payload);

                if(data?.status ===1){
                    message.error(data?.desc);
                    setLoading(false)
                  }    
               else{   
                    message.success("Password reset successful!");
                    setScreen(1);
                    setLoading(false)   
          } 
          
            }catch(error){
                  
        if (error instanceof Error) {
            if(error.message === 'Network Error'){
              message.error('Please check your network connection!');
              setLoading(false)  
             }else{
             
             setLoading(false)
             }
      
           }else{
             setLoading(false)
           }
          
            }
        }
    }

    
    return (
       <>
        

        <Container>
        
            <Wrapper>
            
            <Row>
            <Navbar  setShowModal={setShowModal} showModal={showModal}  token={token}/>
                <Column>
                <Header>
                    N100,000,000
                    <span>GIVEAWAY</span>
                </Header>
                 <Text>Choose Your Favorite ULTI<span style={{fontSize:"27px"}}>MATE</span> and win BIG !</Text>
                </Column>
            </Row>
            <Row height='400px'>
                <Column>
                <Card>
                   <CardAvatar width='70px' height='74px'>
                          <img src={guess} alt='guess' />
                    </CardAvatar>
                    <Text color='var(--humber-golden)' size='1.3rem'>
                     <strong>Guess Game</strong>
                   </Text>
                   <Subtitle><span>Guess correctly and win up to</span> <strong>N50,000</strong> <span>instantly.</span></Subtitle>

                  
                       <p>Make a Guess on a specific event per category and win up to <strong>N50,000 </strong> naira 
instantly if your guess is correct.</p>
                         <button onClick={()=>{
                             {token ? history.push('/guess-game') : setShowModal(true)}}}>Play Now</button>
                </Card>
                </Column>
                <Column>
                <Card>
                    <CardAvatar height='80px'>
                          <img src={avatar} alt='avatar' />
                    </CardAvatar>
                    <Text color='var(--humber-golden)' size='1.3rem' >
                     <strong>Avatar Game</strong>
                   </Text>
                   
                   <Subtitle><span>Get lucky with your avatar, and win up to</span> <strong>N100,000</strong> <span>instantly.</span></Subtitle>
                   <p>Select Avatars of your choice to represent you. Your Avatar will earn 
you Points and Prizes based on activities and performances of contestants. You 
could win the grand prize of <strong>5 million</strong> by choosing correctly.</p>
                   {/* <p>Select any 3 Avatar of your choice to represent you. Your Avatars will earn you points and prizes based on daily activities and performances.
                         You can win the Grand Prize of <strong>N5 million</strong> by choosing correctly.</p> */}
                      <button onClick={()=>{token ? history.push('/avatars') :setShowModal(true)}}>Play Now</button>   
                </Card>
                </Column>
                <Column>
                <Card>
                  <CardAvatar width='auto' height='80px' padding='1.1rem 0 0 0' margin='0 0 1.1rem 0'>
                          <img src={trivia} alt='trivia' />
                    </CardAvatar>
                    <Text color='var(--humber-golden)' size='1.3rem'>
                    <strong> Trivia Game</strong>
                   
                   </Text>
                        <Subtitle><span>Win up to </span> <strong>N10,000</strong> <span>Instantly.</span></Subtitle>
                        <p>This game tests your ability to respond quickly. Coins and Points are awarded for
every correct answer. Players are eligible to win up to <strong>N10,000</strong> naira instantly.</p>
                       

                         <button onClick={()=>{
                             {token ? history.push('/trivia-game') :setShowModal(true)}}}>Play Now</button>
                 </Card>
                 
                </Column>
                {/* <Column>
                <Card>
                  <CardAvatar width='auto' height='100px' padding='1.1rem 0 0 0' margin='0 0 1.1rem 0'>
                          <img src={scramble} alt='avatar' />
                    </CardAvatar>
                    <Text color='var(--humber-golden)' size='1.3rem'>
                    <strong> Scramble Game</strong>
                   
                   </Text>
                        <Subtitle><span>instantly.</span> <strong>N10,000</strong> <span>Instantly</span></Subtitle>
                  
                       <p>Coins and points are awarded for every correct answer.
                            Winners are eligible to win up to <strong>N10,000</strong> Naira INSTANTLY.</p>

                         <button onClick={()=>{
                             {token ? history.push('/scramble') :setShowModal(true)}}}>Play Now</button>
                 </Card>
                 
                </Column> */}
            </Row>

              <Row>
                  <div className='half-circle'>
                      <div className='desc-box'>
                      <div className='desc'> LOTS OF GREAT </div>
                      <div className='desc'>PRIZES TO BE WON </div>
                      </div>
                      <div className='gift1'>
                      <img  src={gift1} alt=''/>
                      </div>
                      <div className='gift2'>
                      <img  src={gift2} alt=''/>
                      </div>
                      <div className='gift3'>
                      <img  src={gift3} alt=''/>
                      </div>
                      <div className='gift4'>
                      <img  src={gift4} alt=''/>
                      </div>
                      <div className='gift5'>
                      <img  src={gift5} alt=''/>
                      </div>
                  </div>
              </Row>

              {showModal && (
                  <CustomModal  setShowModal={setShowModal}  width='400px'>
                  <div>
                      <div className='modal-tab'>
                        
                     <div className='tab' onClick={signupScreen} style={{borderBottom: screen ===2 ? '4px solid var(--humber-golden)': null}}>Sign-up </div>
                     <div className='tab' onClick={loginScreen} style={{borderBottom: screen ===1 ? '4px solid var(--humber-golden)': null}}>Login</div>
                       
                        </div>

                        
                       {screen ===2 && (
                            <div style={{padding:'1rem '}}>
                            <p><strong><span style={{color:"var(--humber-light)"}}>Create a free account on</span> <span style={{color:'var(--humber-golden)'}}>ULTIMATE GAME!</span></strong></p>
                           <form>
                               <input type='text' value={name} onChange={handleChange('name')} placeholder='Enter Name'  />
                               <small>{errors?.nameError}</small>
                               <div className='inputGap'></div>

                               <input type='text' value={email} onChange={handleChange('email')} placeholder='Enter Email'  />
                               <small>{errors?.emailError}</small>
                               <div className='inputGap'></div>
                              
                               <input type='number'value={phone} onChange={handleChange('phone')} placeholder='Enter Phone'  />
                               <small>{errors?.phoneError}</small>
                               <div className='inputGap'></div>
                               
                               <div style={{position:'relative'}}>
                                   
                               <input type={showPassword ? "text" : "password"}   value={password} onChange={handleChange('password')} placeholder='Enter Password'  />
                               <FeatherIcon icon={ showPassword ? "eye" : "eye-off"}  onClick={togglePasswordVisiblity}
                                    style={{position: "absolute",
                                    right: "15px",
                                    top: "13px",
                                    color: "var(--humber-golden)",
                                    width: "18px"}} />
                               </div>
                              
                               <small>{errors?.passwordError}</small>
                               <div className='inputGap'></div>
                               
                               <button type='submit' onClick={registerUser}>{loading ? 'Loading...' : 'Sign up'}</button>
                           </form>
                       </div>
                       )}

                           {screen ===1 && (
                            <div style={{padding:'1rem '}}>
                            <h3><strong>LOGIN</strong></h3>
                           <form>
                               <input type='text'  value={email} onChange={handleChange('email')} placeholder='Enter Email'  />
                               <small>{errors?.emailError}</small>
                               <div className='inputGap'></div>
                                <div style={{position:'relative'}}>
                                <input type={showPassword ? "text" : "password"}   value={password} onChange={handleChange('password')} placeholder='Enter Password'  />
                               <FeatherIcon icon={ showPassword ? "eye" : "eye-off"}  onClick={togglePasswordVisiblity}
                                    style={{position: "absolute",
                                    right: "15px",
                                    top: "13px",
                                    color: "var(--humber-golden)",
                                    width: "18px"}} />
                                </div>
                             
                               <small>{errors?.passwordError}</small>
                               
                               <div className='forgot-password' onClick={()=>setScreen(3)}>Forgot Password?</div>
                               <div className='inputGap'></div>
                               
                               <button type='submit' onClick={loginUser}>{loading ? 'Authenticating...' : 'Login'}</button>
                           </form>
                       </div>
                        )}


                        {screen ===3 && (
                            <div style={{padding:'1rem '}}>
                            <h6><strong>REQUEST OTP</strong></h6>
                            <div className='inputGap'></div>
                           <form>
                               <input type='text'  value={email} onChange={handleChange('email')} placeholder='Enter Email'  />
                               <small>{errors?.emailError}</small>
                               <div className='inputGap'></div>
                               <button type='submit' onClick={forgotPassword}>{loading? 'Verifying...' : 'Request OTP'}</button>
                           </form>
                       </div>
                        )}
                         {screen ===4 && (
                            <div style={{padding:'1rem '}}>
                            <h6><strong>VERIFY OTP</strong></h6>
                           <form>
                               <input type='number'  value={otp} onChange={handleChange('otp')} placeholder='Enter OTP'  />
                               <div className='inputGap'></div>
                              
                               <button type='submit' onClick={completeRegistration}>{loading ? "Verifying ..." : 'Confirm'}</button>
                           </form>
                       </div>
                        )}
                        {screen ===5 && (
                            <div style={{padding:'1rem '}}>
                            <h6><strong>RESET PASSWORD</strong></h6>
                           <form>
                               <input type='text'  value={email} onChange={handleChange('email')} placeholder='Enter Email'  />
                               <small>{errors?.emailError}</small>
                               <div className='inputGap'></div>
                               <input type='number'  value={otp} onChange={handleChange('otp')} placeholder='Enter OTP'  />
                               <small>{errors?.otpError}</small>
                               <div className='inputGap'></div>
                                <div style={{position:'relative'}}>
                                <input type={showPassword ? "text" : "password"}  value={password} onChange={handleChange('password')} placeholder='Enter New Password'  />
                               <FeatherIcon icon={ showPassword ? "eye" : "eye-off"}  onClick={togglePasswordVisiblity}
                                    style={{position: "absolute",
                                    right: "15px",
                                    top: "13px",
                                    color: "#295982",
                                    width: "18px"}} />
                                </div>
                               <small>{password?.otpError}</small>
                               <div className='inputGap'></div>
                               <button type='submit' onClick={confirmReset}>{loading ? "Resetting..." : "Reset Password"}</button>
                               <div className='inputGap'></div>
                                <div>Didn't Receive OTP? <strong style={{cursor:'pointer',color:'#0f0d5f'}}> Resend</strong></div>
                           </form>
                       </div>
                        )}

                  </div>
             </CustomModal>
              )}
            </Wrapper>
        </Container>
       </>
    )
}

export default Home2
