import React, {useRef, useContext} from "react";
import { useHistory } from "react-router-dom";
import Stack from "../../components/Stack";
import dashboard from "./dashboard.module.css";
import questionmark from '../../assets/gulder_assets/guess_logo.png'
import avataricon from '../../assets/gulder_assets/avatar.png'
import scramble from '../../assets/gulder_assets/scramble.png'
import { Card, CardBody, RowContent, PositionWrapper } from "../../components/Activities/activities_styles";

import Layout from '../../components/Layout'
import IdleTimer from 'react-idle-timer';
import triviaicon from '../../assets/gulder_assets/trivia.png'
import Activities from "../../components/Activities/activities";
import { signout } from "../../utils/authdata";
import { StatsContext } from '../../context/'


const Dashboard = () => {

  const { state:{stat} } = useContext(StatsContext)
  const history = useHistory();
  const idleTimerRef = useRef()
  const logout =()=>{
    signout(()=>{
        history.push('/');   
    })
}

console.log("STATE", stat)
  return (
    <IdleTimer  ref={idleTimerRef}  timeout={1000 * 60 * 15} onIdle={logout}>
   <Layout>
  
        <div
      className="zoom"
      style={{
        width: "90%",
        paddingBottom: "50px",
        minHeight: "100vh",
        margin: "50px auto",
        
      }}
      
    >
      <div className='page-title'>Dashboard</div>
      <Activities stat={stat}/>
      {/* <Stack /> */}
      {/* <div classNamw="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">

            </div>
          </div>
        </div>
      </div> */}
      <div className={dashboard.cardwrapper}>
        <div className={dashboard.card}>
          <img className={`${dashboard.cardimg}`}  src={questionmark} alt="questionmark"  />
          <div className={`${dashboard.text} ${dashboard.guesstext} `}>
            Guess Game
          </div>
          <div className={dashboard.description}>
            Put a stake on your favorite TV show
          </div>
          <button
            className={dashboard.btn}
            onClick={() => history.push("/guess-game")}
          >
            Play Now 
          </button>
        </div>
        <div className={dashboard.card}>
          <img src={avataricon} className={`${dashboard.cardimg}`}  alt="questionmark" />
          <div className={` ${dashboard.text} ${dashboard.avatartext}`}>
            Avatar
          </div>
          {/* ${dashboard.blink_me} */}
           <div className={`${dashboard.description }`}>
           Select Avatars of your choice to represent you
             </div> 
          <button  className={dashboard.btn}  onClick={() => history.push("/avatars")}>
            Play Now
          </button>
        </div>
        <div  className={dashboard.card}>
          <img src={triviaicon}  className={`${dashboard.cardimg}`} alt="questionmark" />
          <div className={`${dashboard.text} ${dashboard.triviatext} `}>
            Trivia
          </div>
          <div className={dashboard.description}>
          How well have you been following GUS ?
          </div>
          <button onClick={() => history.push("/trivia-game")}  className={dashboard.btn}>
            Play Now
          </button>
        </div>
        {/* <div  className={dashboard.card}>
          <img src={scramble}  className={`${dashboard.cardimg}`} alt="questionmark" />
          <div className={`${dashboard.text} ${dashboard.triviatext} `}>
            Scramble
          </div>
          <div className={dashboard.description}>
          How well have you been following?
          </div>
          <button onClick={() => history.push("/scramble")}  className={dashboard.btn}>
            Play Now
          </button>
        </div> */}
      </div>
    </div>
   </Layout>
   </IdleTimer>
  );
};

export default Dashboard;
