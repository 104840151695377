import axios from "axios";
import { message } from "antd";


export default (token) => {
  
  //setup interceptors for 401 errors
  axios.interceptors.response.use(
    (response) => {
     
     
      return response;
    },
    (error) => {
      //check the response status
      if (error.response && error.response.status === 401) {
        
       
      
        
        //  setTimeout(() => {
        //   localStorage.clear();
        //   //redirect to the login page
          
        //   window.location = "/dashboard";
        //   // window.location.href = "/";
        // }, 5000);
        return error.response;
        //clear the local storage
       
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );
  if (token) {
    //setting authorization header
    // axios.defaults.headers.common['token'] = token;
  
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};