import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import {isAuthenticated} from './authdata'
import { StatsContext } from '../context/'


// const userObj = JSON.parse(localStorage.getItem("user"));
//         const token = userObj?.token

 


export const useFetchStat = () => {
    const [userCoins, setCoins]= useState()
    const [userPoints, setPoints]= useState()
    const [userTokens, setTokens]= useState()
    const [guessStats, setGuess] = useState()
    const [ triviaStats, setTrivia ] = useState();
    const [loading, setLoading] = useState(false)
    const { state:{stat}, dispatch } = useContext(StatsContext)

    const {token} = isAuthenticated()

    

    const fetchUserStat = async () => {
        setLoading(true)
        try{
            const response = await axios.get(`${process.env.REACT_APP_AUTH_URL}/api/v1/user-stat`, {
                headers: { 'Authorization': `Bearer ${token}`}
            })
            setLoading(false)
            console.log('SOMETHING WAS CALLED')
            dispatch({
                type:"STATS",
                payload:response?.data
            })
            setCoins(response.data.COINS)
            setPoints(response.data.POINTS)
            setTokens(response.data.TOKEN)
        }catch(error){
            setLoading(false)
            // console.log('from user stats error is >>', error);
        }
    }

    const fetchGuess = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_GUESS_GAME_URL}/guess-game-answer/game-stat`,{
                headers: { 'Authorization': `${token ? token :''}`}
            })
            // console.log(response)
            const guessstats = response.data.obj.guessGame
            setGuess(guessstats)
        }catch(err){
            console.log(err.response)

        }
    }

    const fetChTrivial = async  () => {
        try {   
            const response = await axios.get(`${process.env.REACT_APP_GUESS_GAME_URL}/trivia-game-answer/game-stat`,{
                headers: { 'Authorization': `${token ? token :''}`}
            })
            // setTrivia
            const trivials = response.data.obj.triviaGame
            // console.log(response)
            
            setTrivia(trivials )
        }catch(err){
            console.log(err.response)
        }
    }
    useEffect(() => {
        fetchUserStat()
        fetchGuess()
        fetChTrivial()
    }, [])
    
    // console.log('my token is ', userTokens)

    return {
        userCoins, 
        userPoints, 
        userTokens,
        guessStats,
        triviaStats,
        loading
    }
}

