import React from "react";
import styles from "./triviacategory.module.css";

import triviaicon from '../../assets/gulder_assets/scramble.png'

import timericon from "../../assets/images/timericon.png";
// import Cost from "../Cost";
import TopModal from "../../components/Modal";
import { useState, useEffect } from 'react'
import Payments from "../../components/Payments";
import axios from "axios";
import  { isAuthenticated } from '../../utils/authdata'

import FeatherIcon from 'feather-icons-react'

const Scramble = ({
    setShowScramble
}) => {
  
  
 
 
 
  const playNow = () => {
    setShowScramble(scramble=>!scramble )
  }
   
 
  
  return (
    <div>
      <div className={styles.topwrapper}>
        <img
          className={`${styles.image_cat}`}
          src={triviaicon}
          alt="triviaicon"
        />
        <div className={styles.title}>Scramble games</div>
        <div className={styles.description}>
        Select a Category to start your game
        </div>
        {/* <Cost /> */}
      </div>
      <div className={styles.catstory}>
        <img
          src={timericon}
          alt="timericon"
          className={`${styles.trivia_timer}`}
          style={{ marginTop: "20px" }}
        />
        <div className={styles.readme}>
        You have 20 secs to unscramble word and game costs 5 Tokens
        </div>
        <button
          onClick={playNow}
          className={styles.btn}
        >
          play now
        </button>
      </div>
      {/* {topUp && (
        <TopModal
          modalClass={"topup_modal_content"}
          dialogClassName={"topUpclass"}
          closeModal={() => setTopUp(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <Payments
            GetTrivias={GetTrivias}
            setAmmountAdded={setAmmountAdded}
            setTopUp={setTopUp}
            setSuccessModal={setSuccessModal}
            successModal={successModal}
          />
        </TopModal>
      )} */}
      {/* {successModal && (
        <TopModal
          modalClass={"sss"}
          dialogClassName={"topUpsuccess"}
          closeModal={() => setSuccessModal(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <div className="text-center mb-4">
            
            <FeatherIcon icon='check-circle' size="50px" color="#198754"/>
          </div>
          <div className={styles.tokenAdded}> 
              
              {formatNumber(amountAdded ? amountAdded / 10 : "")} Token(s)
          </div>
          <div
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "bolder",
            }}
            className={`${styles.text} mt-4 mb-4 text-center` } style={{fontSize:'1rem', fontWeight:'400'}}
          >
             has been added to your wallet
          </div>

          <div className=" d-flex mt-4 mb-4 justify-content-center">
            <button onClick={()=>{
              setSuccessModal(false)
              showTriviabox(true);
              GetTrivias();
              
            }}  className={styles.topupsuccess}>Play Game</button>
          </div>
        </TopModal>
      )} */}
    </div>
  );
};

export default Scramble;
