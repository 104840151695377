import React from "react";

import styles from "./style.module.css";

const Howtoplay = () => {
  return (
    <>
      <div className="zoom"  >
      {/* style={{border: "1px solid var(--humber-golden)", padding: "15px", borderRadius: "10px"}} */}
        <div >
          {/* <strong className={styles.title}>General Information</strong> */}
          <div
            style={{
              padding: "10px 0",
              fontSize: "16px",
              lineHeight: "1.7rem",
            }}
          >
            <strong>Welcome to the  Ultimate  Games!</strong> <br />
            <small><i>Every player is a winner! </i></small>
            
          </div>
          <strong className={styles.title}>How to Play?</strong>  
          
          <div
            style={{
              padding: "10px 0",
              fontSize: "16px",
              lineHeight: "1.7rem",
            }}
          >
            Deposit a minimum of ₦100 to participate in the games. The value of
            your deposit is given as tokens and the reward for every successful
            gameplay is in "Coins". Players will additionally earn Points for{" "}
            <strong style={{ fontSize: "1rem" }}>every key activity</strong> on
            the platform, such as "Sign Up, Gameplay, and Deposit".
          </div>
          <div style={{ padding: "5px 0", fontSize: "16px" }}>
            Sign up to the Gulder Utimate Games website -
            www.theultimategames.com
          </div>
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
              <li>
                A one time verification code is sent to the Player
                via <strong>EMAIL</strong>.
              </li>
              <li>
                Enter the code and get signed into the  Ultimate 
                games account.
              </li>
            </ul>
          </div>
         
          {/* <strong className={styles.title}>Payment</strong> */}
          {/* <div style={{ fontSize: "16px" }}>
            Players can buy tokens to play the  ultimate  games.
          </div>
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
              <li> Minimum deposit of ₦100 will get you 10 Tokens.</li>
              <li>
              For every correct gameplay, players earn Coins that can be used to redeem prizes.

              </li>
              <li>
              For every activity on the platform, players earn Points, which increases ranking on the leaderboard. You can also redeem prices with your Points.
              </li>
             
            </ul>
          </div> */}
          <strong className={styles.title}>GAMES</strong>
          <div style={{ padding: "5px 0", fontSize: "16px" }}>
            You can play the Avatar, Guess , Trivia and Word Scramble Game.
          </div>
          <strong style={{ color: "var(--humber-golden)" }}>Avatar Game</strong> <br />
          <p className={styles.myp}>
            
            <div style={{ padding: "5px 0", fontSize: "16px", color: "#ffffffbd" }}>
              The Gulder "Avatar Games" is one in which players can pick Avatars
              to represent them in a game for the purpose of scoring game
              points. Avatars will be ranked and given positions based on their
              performance in the show. Players who select the highest-scoring
              Avatars shall earn Coins earmarked for each round of Avatar
              activities.
            </div>
          </p>
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
              <li>
                Avatar is an icon or figure representing you and the Contestants
                participating in the Gulder Ultimate show.
              </li>
              <li>An Avatar game play costs 10 tokens</li>
              <li>
                Each Avatar score increases based on their performance in the
                show. The number of Coins earned by each successful Player
                depends on the number of Players who choose correctly.
              </li>
             
            </ul>
          </div>
          <strong style={{ color:  "var(--humber-golden)" }}>Trivia Game</strong>{" "}
            <br />
          <p className={styles.myp}>
            
            <div
              style={{
                padding: "5px 0",
                fontSize: "16px",
                color: "#ffffffbd",
              }}
            >
             Trivia is a multiple-choice game about the Gulder Ultimate Search show, Gulder brand, Entertainments, History, Arts and Crafts in Nigeria. 
            </div>
          </p>
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
              <li>Trivia cost 2 tokens per play.</li>
              <li>You earn a Coin for every correct answer and points for playing the game.</li>
            </ul>
          </div>
          <strong style={{ color:  "var(--humber-golden)" }}>Guess Game</strong>{" "}
            <br />
          <p className={styles.myp}>
           
            <div
              style={{
                padding: "5px 0",
                fontSize: "16px",
                color: "#ffffffbd",
              }}
            >
             This game allows players to speculate possible events in the Gulder Ultimate Search show based on available categories. Each event are timed and player must put in their guesses before the end of the timer.
            </div>
          </p>
          
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
              <li>Guess costs 10 tokens per play</li>
              <li>
                Number of Coins earned for each correct guess, depends on number
                of Players who equally guessed correctly. 
              </li>
            </ul>
          </div>
          <strong style={{ color:  "var(--humber-golden)" }}>Word Scramble</strong>{" "}
            <br />
          <p className={styles.myp}>
           
            <div
              style={{
                padding: "5px 0",
                fontSize: "16px",
                color: "#ffffffbd",
              }}
            >
            In the Word Scramble game, players are to unscramble the letters displayed on their screen into a correct word form.  To play the word scramble game cost tokens and players earn coins and points for every correct unscrambled word.
            </div>
          </p>
          <strong className={styles.title}>LEADERBOARD</strong>
          <div
              style={{
                padding: "5px 0",
                fontSize: "16px",
                color: "#ffffffbd",
              }}
            >
            The leaderboard shows the ranking of  every player in the Ultimate Games.
            </div>
          <div style={{ margin: "10px 0" }}>
            <ul className={styles.myp}>
            <li>Player gets points for every activity done on the Games platform.</li>
              <li> Your "Points" get you a position on the Leaderboard.</li>
              <li>
                Players on the leaderboard will be eligible for amazing "FLASH"
                rewards from time to time.
              </li>
            </ul>
          </div>
         
          <strong className={styles.title}>REDEEM PRIZES</strong>
          <div
            style={{
              padding: "5px 0",
              fontSize: "16px",
              
            }}
          >
            You can redeem prizes with your{" "}
            <strong style={{ fontSize: "1rem" }}> Coins</strong> and with your{" "}
            <strong style={{ fontSize: "1rem" }}> Points </strong>. Click on
            “Redeem Prize” as they appear on your profile page. Select prizes
            that your coins or points cover accordingly and follow the steps
            displayed to get prizes.
          </div>
        </div>
      </div>
    </>
  );
};

export default Howtoplay;
